import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {
  apiKey,
  requirement_pickup_api,
  requirement_pickup,
  check_product_series_for_out,
} from "../../Api";
import CustomLoader from "../../Components/Loader";
import { useScreenWidth } from "../../useScreenWidth";
import { Breadcrumbs, Button, Typography } from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import Quagga from "quagga";
import Webcam from "react-webcam";
import { BsUpcScan } from "react-icons/bs";
import soundEffect from "../../Assets/sounds/beep.mp3";
import { debounce } from "lodash";
import { Input, Label } from "semantic-ui-react";

function PickUp() {
  const isWideScreen = useScreenWidth();
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);

  const [loading, setLoading] = useState(true);
  const [productSKU, setProductSKU] = useState("");
  const [productName, setProductName] = useState("");
  const [partnumber, setPartNumber] = useState("");
  const [remainingQty, setRemQty] = useState("");
  const [ischild, setIsChild] = useState(0);
  const [reqQty, setReqQty] = useState("");
  const [suggestedData, setSuggestedData] = useState([]);
  const [pickedProducts, setPickedProducts] = useState([]);
  const { pk } = useParams();
  const [searchQuery, setSearchQuery] = useState("");

  const ws = useRef(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const webcamRef = useRef(null);
  const [scannedProducts, setScannedProducts] = useState([]);
  const [scanData, setScanData] = useState("");

  const [sku, setSku] = useState("");
  const [productDataa, setProductDataa] = useState([]);
  const [skuToRemove, setSkuToRemove] = useState("");
  const [additionalInfoToRemove, setAdditionalInfoToRemove] = useState(null);
  const [openRemoveP, setOpenRemoveP] = useState(false);
  const [openReallocate, setOpenReallocate] = useState(false);

  const navigate = useNavigate();
  const inputRef = useRef(null);
  const hiddenInputRef = useRef(null);

  useEffect(() => {
    const handleFocus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }

      // Call your input change handler with scanData
      handleInputChange(scanData);

      // Immediately blur the input to hide the keyboard
      if (inputRef.current) {
        inputRef.current.blur();
      }
    };

    document.addEventListener("click", handleFocus);

    return () => {
      document.removeEventListener("click", handleFocus);
    };
  }, []);

  const playSound = () => {
    const sound = document.getElementById("scanSound");
    if (sound) {
      sound.play();
    }
  };

  const handleOpenCamera = () => {
    setIsCameraOpen(true);
  };

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
  };

  const handleDetected = useCallback((result) => {
    const scannedCode = result.codeResult.code;
    setScanData(scannedCode);
  }, []);

  useEffect(() => {
    handleInputChange(scanData);
  }, [scanData]);

  const isValidProductSku = (value) => {
    const regex = /^\d{6}_\d+$/; // First 5 characters are digits, followed by an underscore, then more digits
    return regex.test(value);
  };

  const handleInputChange = (scanData) => {
    const newValue = scanData;
    const checkValue = newValue[0];

    if (newValue.length <= 5) {
      setScanData("");
      return;
    }

    const checksku = newValue.split("_")[0];

    if (newValue.length > 5) {
      if (checkValue === "W") {
        playSound();
        toast.error("Oops! You have scanned a warehouse.");
      } else if (checksku === productSKU.split("-")[1]) {
        if (isValidProductSku(newValue)) {
          const seriesNumber = parseInt(newValue.split("_")[1], 10);

          const pickedArray = pickedProducts.map((data) =>
            parseInt(data.series_number, 10)
          );
          if (pickedArray.includes(seriesNumber)) {
            toast.error("Product Series Already Exists!");
            setScanData("");
            return;
          }

          const seriesExists = scannedProducts.some(
            (product) =>
              product.sku === checksku &&
              product.additional_info?.includes(seriesNumber)
          );

          if (seriesExists) {
            setSkuToRemove(checksku);
            setAdditionalInfoToRemove(seriesNumber);
            setOpenRemoveP(true);
          } else {
            fetchProductData(newValue);
            setIsCameraOpen(false);
            playSound();
          }
        } else {
          playSound();
        }
      } else {
        playSound();
        toast.error("Oops! You have scanned the wrong product.");
      }
    }

    setScanData("");
  };

  const fetchProductData = useCallback(
    debounce((sku) => {
      axios
        .get(check_product_series_for_out, {
          params: {
            product_value: sku,
            is_from_picking: 1,
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          const productData = response.data.product;
          addScannedProduct(sku, productData);
          setScanData("");
        })
        .catch((error) => {
          console.log(error);
          let errorstatus = error.response.data.status;
          const productData = error.response.data.product;
          if (errorstatus == 2) {
            setOpenReallocate(true);
            setError(
              `${error.response.data.message}. Do you want to Re-allocate ?`
            );
            setError2("Product already allocated!");
            setProductDataa(productData);
            setSku(sku);
            setScanData("");
          } else {
            console.log(error.response);
            toast.error(error.response.data.message);
            setScanData("");
          }
        });
    }, 500),
    []
  );

  const addScannedProduct = useCallback(
    debounce((sku, productData) => {
      const [baseSku, additionalInfoString] = sku.split("_");
      const additionalInfo = parseInt(additionalInfoString, 10);

      if (!productData) {
        toast.error("Product not found for the scanned SKU.");
        return;
      }

      setScannedProducts(
        (prev) => {
          const existingProduct = prev.find((item) => item.sku === baseSku);

          if (existingProduct) {
            if (existingProduct.additional_info?.includes(additionalInfo)) {
              setSkuToRemove(baseSku);
              setAdditionalInfoToRemove(additionalInfo);
              setOpenRemoveP(true);

              return prev;
            }

            return prev.map((item) =>
              item.sku === baseSku
                ? {
                    ...item,
                    qty: item.qty + 1,
                    additional_info: [
                      ...(item.additional_info || []),
                      additionalInfo,
                    ],
                  }
                : item
            );
          } else {
            return [
              ...prev,
              {
                sku: baseSku,
                product_name: productData.product_name,
                product_sku: productData.product_sku,
                qty: 1,
                additional_info: [additionalInfo],
              },
            ];
          }
        },
        (notAdded) => {
          if (notAdded) {
            alert("Product was scanned but not added. Try Again!");
          }
        }
      );
    }, 300),
    [] // dependencies
  );

  const submitScannedProducts = () => {
    let { additional_info, qty } = scannedProducts[0];

    let submitdata = { series: additional_info, qty, item_pk: parseInt(pk) };

    axios
      .post(requirement_pickup_api, submitdata, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        navigate(-1);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const submitSmallProducts = (wareHouse, quantities) => {
    const submitDataArray = []; // Initialize the array to hold all data

    // if (wareHouse && quantities && Object.keys(quantities).length > 0) {
    // Ensure all quantities are integers and filter out any with 0 or empty value
    const parsedQuantities = Object.keys(quantities).reduce((acc, key) => {
      const parsedValue = parseInt(quantities[key], 10);
      if (parsedValue !== 0 && !isNaN(parsedValue)) {
        acc.push(parsedValue); // Push valid quantities to an array
      }
      return acc;
    }, []);

    if (parsedQuantities.length > 0) {
      // Add each quantity to the submitDataArray
      parsedQuantities.forEach((quantity) => {
        submitDataArray.push({
          ware_house: wareHouse,
          quantity: quantity,
        });
      });
    }
    // } else {
    //   toast.error("Invalid warehouse or quantities data.");
    // }

    return submitDataArray; // Return the accumulated array (without item_pk)
  };

  const filterQuantitiesByReqIndex = (quantities, reqIndex) => {
    return Object.keys(quantities).reduce((filtered, key) => {
      if (key.startsWith(`${reqIndex}-`)) {
        filtered[key] = quantities[key];
      }
      return filtered;
    }, {});
  };

  const submitAllWarehouses = () => {
    const allSubmitData = [];
    const itemPk = parseInt(pk); // Assuming pk is already available and parsed

    suggestedData.forEach((req, reqIndex) => {
      const wareHouse = req.warehouse_pk;
      const filteredQuantities = filterQuantitiesByReqIndex(
        quantities,
        reqIndex
      );

      // Get the data for each warehouse and add it to the allSubmitData array
      const submitDataForWarehouse = submitSmallProducts(
        wareHouse,
        filteredQuantities
      );

      // Concatenate the current warehouse's data into the allSubmitData array
      allSubmitData.push(...submitDataForWarehouse);
    });

    if (allSubmitData.length > 0) {
      const totalQuantity = allSubmitData.reduce(
        (sum, data) => sum + data.quantity,
        0
      );
      // Prepare final data structure with item_pk outside the objects
      const submitPayload = {
        item_pk: itemPk, // Send item_pk once at the top level
        details: allSubmitData,
        t_qty: parseInt(totalQuantity), // Send the warehouse and quantity data
      };

      axios
        .post(requirement_pickup_api, submitPayload, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          toast.success(response.data.message);
          navigate(-1);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error("No valid data to submit.");
    }
  };

  // console.log(suggestedData);

  const [quantities, setQuantities] = useState({});

  const maxQty = reqQty - remainingQty || 0;

  const handleQuantityChange = (index, value) => {
    const parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue) || parsedValue < 0) {
      toast.error("Invalid quantity entered.");
      setQuantities((prevState) => ({
        ...prevState,
        [index]: "",
      }));
      return;
    }

    // Calculate the total quantity after the change
    const totalQty = Object.values(quantities).reduce(
      (sum, qty) => sum + qty,
      0
    );
    const newTotalQty = totalQty - (quantities[index] || 0) + parsedValue;

    if (newTotalQty > maxQty) {
      toast.error(`Total quantity cannot exceed ${maxQty}.`);
      setQuantities((prevState) => ({
        ...prevState,
        [index]: "",
      }));
      return;
    }

    setQuantities((prevState) => ({
      ...prevState,
      [index]: parsedValue,
    }));
  };
  // console.log(quantities);

  useEffect(() => {
    if (isCameraOpen && webcamRef.current) {
      const interval = setInterval(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          Quagga.decodeSingle(
            {
              src: imageSrc,
              numOfWorkers: 0, // Needs to be 0 when used with `decodeSingle`
              inputStream: {
                size: 800,
              },
              decoder: {
                readers: ["code_128_reader"],
              },
            },
            (result) => {
              if (result && result.codeResult) {
                handleDetected(result);
              }
            }
          );
        }
      }, 400);

      return () => clearInterval(interval);
    }
  }, [isCameraOpen, handleDetected]);

  useEffect(() => {
    // Establish WebSocket connection with API-Key as a query parameter
    ws.current = new WebSocket(
      `${requirement_pickup}?requirement_item_pk=${pk}&API-Key=${apiKey}`
    );

    ws.current.onopen = () => {
      console.log("WebSocket connected");
      setLoading(false);
    };

    ws.current.onmessage = (event) => {
      const response = JSON.parse(event.data);
      // console.log(response);
      setProductSKU(response.obj.product_sku);
      setProductName(response.obj.product_name);
      setPartNumber(response.obj.part_number);
      setRemQty(response.obj.picked_up_quantity);
      setReqQty(response.obj.required_quantity);
      Object.values(response.inventory).forEach((data) => {
        setIsChild(data.is_child);
      });
      setSuggestedData(Object.values(response.inventory).map((data) => data));
      setPickedProducts(
        Object.values(response.obj.picked_up_detail).map((data) => data)
      );
    };

    ws.current.onerror = (error) => {
      console.error(error, "WebSocket error");
    };

    ws.current.onclose = () => {
      console.log("WebSocket disconnected");
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [pk, apiKey]);

  useEffect(() => {
    if (!loading) {
      fetchData();
    }
  }, [searchQuery]);

  const fetchData = (query = searchQuery) => {
    const bodyData = {
      requirement_pk: parseInt(pk),
      search: query,
    };

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(bodyData));
    }
  };

  const aggregateInventory = (inventory) => {
    const aggregatedData = {};

    inventory.forEach((inv) => {
      const key = `${inv.rack__row__row_name}-${inv.rack__rack_name}`;
      if (!aggregatedData[key]) {
        aggregatedData[key] = {
          rack__row__row_name: inv.rack__row__row_name,
          rack__rack_name: inv.rack__rack_name,
          quantity: 0,
        };
      }
      aggregatedData[key].quantity += 1; // Assuming each item has a quantity of 1
    });

    return Object.values(aggregatedData);
  };

  const groupProducts = (products) => {
    const groupProducts = products.reduce((acc, product) => {
      const key = `${product.warehouse_name}-${product.row_name}-${product.rack_name}`;
      if (!acc[key]) {
        acc[key] = {
          ...product,
          series_numbers: [product.series_number],
        };
      } else {
        acc[key].qty += product.qty;
        acc[key].series_numbers.push(product.series_number);
      }
      return acc;
    }, {});

    return Object.values(groupProducts);
  };

  const groupedProducts = groupProducts(pickedProducts);

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />
      <audio id="scanSound" src={soundEffect} />
      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate(-1)} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Requirement</Typography>
              <Typography sx={{ color: "text.primary" }}>Pick Up</Typography>
            </Breadcrumbs>
          </div>

          {isCameraOpen ? (
            <div className="scannerDiv">
              <Webcam
                audio={false}
                ref={webcamRef}
                width={1920}
                height={220}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  facingMode: "environment",

                  border: "none",
                }}
                style={{
                  marginBottom: "12px",
                  border: "3px dashed green",
                  padding: "0",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
              <Button
                variant="outlined"
                color="error"
                onClick={handleCloseCamera}
              >
                Close
              </Button>
            </div>
          ) : null}

          {ischild == 1 ? (
            <>
              <Button
                style={{ float: "right", marginTop: "-27px" }}
                variant="contained"
                size="small"
                disabled={!quantities}
                onClick={submitAllWarehouses}
              >
                Confirm Pick Up
              </Button>
            </>
          ) : (
            <>
              <Button
                style={{ float: "right", marginTop: "-27px" }}
                variant="contained"
                size="small"
                disabled={scannedProducts && scannedProducts.length < 1}
                onClick={submitScannedProducts}
              >
                Confirm Pick Up
              </Button>
            </>
          )}

          <div className="card p-3 my-2">
            <h5>Product Details:</h5>
            <div className="row">
              <div className="col-lg-2">#{productSKU}</div>
              <div
                className="col-lg-5"
                style={{ borderLeft: "1px solid #c7c7c7" }}
              >
                {productName}
              </div>
              <div
                className="col-lg-3"
                style={{ borderLeft: "1px solid #c7c7c7" }}
              >
                Part No.: {partnumber}
              </div>
              <div
                className="col-lg-1"
                style={{ borderLeft: "1px solid #c7c7c7" }}
              >
                Reqd Qty: {reqQty || 0}
              </div>

              <div
                className="col-lg-1"
                style={{ borderLeft: "1px solid #c7c7c7" }}
              >
                Remaining Qty:{" "}
                <b style={{ color: "red" }}>{reqQty - remainingQty || 0}</b>
              </div>
            </div>
          </div>

          <div className="my-2">
            {reqQty - remainingQty == 0 ? null : ischild === 1 ? null : (
              <>
                <input
                  autoFocus
                  value={scanData}
                  ref={inputRef}
                  style={{
                    border: "1px solid #d1d1d1",
                    borderRadius: "4px",
                    maxWidth: "150px",
                    fontSize: "15px",
                    color: "grey",
                    padding: "5px",
                    display: "none",
                  }}
                  onChange={(e) => setScanData(e.target.value)}
                />

                <input
                  ref={hiddenInputRef}
                  onChange={(e) => setScanData(e.target.value)}
                  value={scanData}
                  style={{
                    border: "1px solid #d1d1d1",
                    borderRadius: "4px",
                    maxWidth: "150px",
                    fontSize: "15px",
                    color: "grey",

                    padding: "5px",
                  }}
                />

                {isCameraOpen ? (
                  <>
                    <BsUpcScan
                      onClick={handleCloseCamera}
                      style={{
                        fontSize: "25px",
                        margin: "2px",
                        marginRight: "5px",

                        cursor: "pointer",
                        top: "10px",
                        color: "red",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <BsUpcScan
                      onClick={handleOpenCamera}
                      style={{
                        fontSize: "25px",
                        margin: "2px",
                        marginLeft: "8px",

                        cursor: "pointer",
                        marginTop: "-3px",
                        color: "green",
                      }}
                    />
                  </>
                )}
              </>
            )}

            <div className="row mt-1">
              {ischild == 1 ? null : (
                <>
                  <div className="col-md-5">
                    {isWideScreen ? (
                      <>
                        <div className="responsive-table1">
                          <table className="ui table celled" id="c_table">
                            <thead className="table-head">
                              <tr>
                                <th>Qty</th>
                                <th>Series</th>
                              </tr>
                            </thead>
                            <tbody>
                              {scannedProducts.length > 0 ? (
                                <>
                                  {scannedProducts &&
                                    scannedProducts.map((product) => (
                                      <>
                                        <tr>
                                          <td>
                                            {product.additional_info &&
                                              product.additional_info
                                                .length}{" "}
                                            QTY
                                          </td>
                                          <td>
                                            {product.additional_info &&
                                              product.additional_info.join(
                                                ", "
                                              )}
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <td
                                      colSpan={2}
                                      style={{
                                        textAlign: "center",
                                        color: "red",
                                      }}
                                    >
                                      No Scanned Products
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="card p-2 responsive-mobile-tablee">
                          {scannedProducts.length > 0 ? (
                            <>
                              {scannedProducts &&
                                scannedProducts.map((product) => (
                                  <>
                                    <div className="product-card">
                                      <div className="py-1 px-2">
                                        <h5 className="product-card-sku">
                                          #{product.product_sku}
                                        </h5>
                                        <h5 className="product-card-name">
                                          {product.product_name}
                                        </h5>
                                        <Label
                                          color="orange"
                                          style={{ marginTop: "5px" }}
                                        >
                                          {product.additional_info &&
                                            product.additional_info.length}{" "}
                                          QTY
                                        </Label>
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </>
                          ) : (
                            <>
                              <div className="card p-3 text-center">
                                <h6>
                                  {" "}
                                  {reqQty - remainingQty == 0
                                    ? "No Remaining Pickups"
                                    : "Not Found"}
                                </h6>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}

                    <h5>Scanned Products:</h5>
                    {isWideScreen ? (
                      <>
                        <div className="responsive-table1">
                          <table className="ui table celled" id="c_table">
                            <thead className="table-head">
                              <tr>
                                <th>Warehouse</th>
                                <th>Row</th>
                                <th>Rack</th>
                                <th>Qty</th>
                                <th>Series</th>
                              </tr>
                            </thead>
                            <tbody>
                              {groupedProducts.length > 0 ? (
                                <>
                                  {groupedProducts.map((product, index) => (
                                    <tr key={index}>
                                      <td>{product.warehouse_name}</td>
                                      <td>{product.row_name}</td>
                                      <td>{product.rack_name}</td>
                                      <td>{product.qty} QTY</td>
                                      <td>
                                        {product.series_numbers.join(", ")}
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <td
                                      colSpan={4}
                                      style={{
                                        textAlign: "center",
                                        color: "red",
                                      }}
                                    >
                                      No Scanned Products
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="card p-2">
                          {groupedProducts.length > 0 ? (
                            <>
                              {groupedProducts &&
                                groupedProducts.map((product) => (
                                  <>
                                    <div className="product-card">
                                      <div className="py-1 px-2">
                                        <h5 className="product-card-name">
                                          {product.warehouse_name}
                                        </h5>
                                        <h5 className="product-card-name">
                                          Row : {product.row_name} -- Rack :{" "}
                                          {product.rack_name}
                                        </h5>

                                        <h5 className="product-card-mrp"></h5>
                                        <Label
                                          color="orange"
                                          style={{
                                            position: "absolute",
                                            bottom: "5px",
                                            right: "5px",
                                          }}
                                        >
                                          {product.qty} QTY
                                        </Label>

                                        <h5 className="product-card-mrp">
                                          <span style={{ color: "grey" }}>
                                            Series:
                                          </span>{" "}
                                          {product.series_numbers.join(", ")}
                                        </h5>
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </>
                          ) : (
                            <>
                              <h5>Not Found</h5>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}

              <div className={ischild == 1 ? "col-md-8" : "col-md-7"}>
                {isWideScreen ? (
                  <div className="responsive-table1">
                    {suggestedData.length > 0 ? (
                      suggestedData.map((req, reqIndex) => {
                        const aggregatedInventory = aggregateInventory(
                          req.inventory
                        );

                        return (
                          <div className="row" key={reqIndex}>
                            <div className="col-md-12">
                              <Label
                                style={{
                                  marginTop: "6px",
                                  maxWidth: "150px",
                                  marginBottom: "2px",
                                  display: ischild === 1 ? "none" : "block",
                                }}
                                as="a"
                                tag
                                color="red"
                              >
                                {req.ware_house}
                              </Label>
                              <table
                                style={{ margin: "0" }}
                                className="ui table celled"
                                id="c_table"
                              >
                                <thead className="table-head">
                                  <tr>
                                    <th>#</th>
                                    {ischild !== 1 ? <th>Row</th> : null}
                                    {ischild !== 1 ? <th>Rack</th> : null}
                                    {ischild === 1 ? <th>Warehouse</th> : null}

                                    <th>Avail Qty</th>
                                    {ischild === 1 ? <th>Quantity</th> : null}
                                  </tr>
                                </thead>
                                <tbody>
                                  {req.unallocated && req.unallocated > 0 ? (
                                    <>
                                      <tr>
                                        <td colSpan="3">Unallocated </td>
                                        <td>{req.unallocated}</td>
                                      </tr>
                                    </>
                                  ) : null}

                                  {ischild === 1
                                    ? req.inventory &&
                                      req.inventory.map((inv, invIndex) => (
                                        <tr key={invIndex}>
                                          <td style={{ width: "5%" }}>
                                            {invIndex + 1}
                                          </td>

                                          <td style={{ width: "25%" }}>
                                            {req.ware_house}
                                          </td>

                                          <td style={{ width: "25%" }}>
                                            {inv.quantity} Qty
                                          </td>
                                          <td style={{ width: "35%" }}>
                                            <Input
                                              placeholder="Qty"
                                              key={`${reqIndex}-${invIndex}`}
                                              value={
                                                quantities[
                                                  `${reqIndex}-${invIndex}`
                                                ] || ""
                                              }
                                              onChange={(e) =>
                                                handleQuantityChange(
                                                  `${reqIndex}-${invIndex}`,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </td>
                                        </tr>
                                      ))
                                    : aggregatedInventory &&
                                      aggregatedInventory.map(
                                        (inv, invIndex) => (
                                          <>
                                            <tr key={invIndex}>
                                              <td style={{ width: "5%" }}>
                                                {invIndex + 1}
                                              </td>
                                              <td style={{ width: "40%" }}>
                                                {inv.rack__row__row_name || "-"}
                                              </td>
                                              <td style={{ width: "40%" }}>
                                                {inv.rack__rack_name || "-"}
                                              </td>
                                              <td style={{ width: "25%" }}>
                                                {inv.quantity} Qty
                                              </td>
                                              {ischild === 1 ? (
                                                <td style={{ width: "35%" }}>
                                                  <Input
                                                    placeholder="Qty"
                                                    value={
                                                      quantities[
                                                        `${reqIndex}-${invIndex}`
                                                      ] || ""
                                                    }
                                                    onChange={(e) =>
                                                      handleQuantityChange(
                                                        `${reqIndex}-${invIndex}`,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </td>
                                              ) : null}
                                            </tr>
                                          </>
                                        )
                                      )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={5}>
                          {!loading ? (
                            <div className="notfound_img_div">
                              <div className="notfound_img"></div>
                            </div>
                          ) : (
                            <div className="notfound_img_div">
                              <div className="loadingfound_img"></div>
                            </div>
                          )}
                        </td>
                      </tr>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="responsive">
                      <h5 className="mt-3">Suggestions:</h5>
                      {suggestedData.length > 0 ? (
                        suggestedData.map((req, reqIndex) => {
                          const aggregatedInventory = aggregateInventory(
                            req.inventory
                          );

                          return (
                            <>
                              <div key={reqIndex}>
                                {ischild === 1
                                  ? req.inventory &&
                                    req.inventory.map((inv, invIndex) => (
                                      <>
                                        <div
                                          className="product-card"
                                          key={invIndex}
                                        >
                                          <div className="py-1 px-2">
                                            <h5 className="product-card-name">
                                              {req.ware_house}
                                            </h5>
                                            <h5 className="product-card-mrp">
                                              <span
                                                style={{ color: "#c7c7c7" }}
                                              >
                                                Available Qty:
                                              </span>{" "}
                                              <b>{inv.quantity}</b>
                                            </h5>

                                            <Input
                                              style={{
                                                position: "absolute",
                                                bottom: "5px",
                                                right: "5px",
                                                width: "100px",
                                              }}
                                              placeholder="Qty"
                                              key={`${reqIndex}-${invIndex}`}
                                              value={
                                                quantities[
                                                  `${reqIndex}-${invIndex}`
                                                ] || ""
                                              }
                                              onChange={(e) =>
                                                handleQuantityChange(
                                                  `${reqIndex}-${invIndex}`,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ))
                                  : aggregatedInventory &&
                                    aggregatedInventory.map((inv, invIndex) => (
                                      <>
                                        <div
                                          className="product-card"
                                          key={invIndex}
                                        >
                                          <div className="py-1 px-2">
                                            <h5 className="product-card-name">
                                              {req.ware_house}
                                            </h5>
                                            <h5 className="product-card-name">
                                              <span style={{ color: "grey" }}>
                                                Row :
                                              </span>{" "}
                                              {inv.rack__row__row_name || "-"}{" "}
                                              <span style={{ color: "grey" }}>
                                                -- Rack :
                                              </span>{" "}
                                              {inv.rack__rack_name || "-"}{" "}
                                            </h5>
                                            <h5 className="product-card-mrp">
                                              <span
                                                style={{ color: "#c7c7c7" }}
                                              >
                                                Available Qty:
                                              </span>{" "}
                                              <b>{inv.quantity}</b>
                                            </h5>

                                            {ischild === 1 ? (
                                              <Input
                                                style={{
                                                  position: "absolute",
                                                  bottom: "5px",
                                                  right: "5px",
                                                  width: "100px",
                                                }}
                                                placeholder="Qty"
                                                value={
                                                  quantities[
                                                    `${reqIndex}-${invIndex}`
                                                  ] || ""
                                                }
                                                onChange={(e) =>
                                                  handleQuantityChange(
                                                    `${reqIndex}-${invIndex}`,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                      </>
                                    ))}

                                {req.unallocated && req.unallocated > 0 ? (
                                  <>
                                    <div
                                      className="product-card"
                                      style={{ backgroundColor: "#ffd7d4" }}
                                    >
                                      <div className="py-1 px-2">
                                        <h5 className="product-card-name">
                                          Unallocated :{" "}
                                          <b style={{ color: "red" }}>
                                            {req.unallocated}
                                          </b>
                                        </h5>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5}>
                            {!loading ? (
                              <div className="notfound_img_div">
                                <div className="notfound_img"></div>
                              </div>
                            ) : (
                              <div className="notfound_img_div">
                                <div className="loadingfound_img"></div>
                              </div>
                            )}
                          </td>
                        </tr>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PickUp;
