import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { apiKey, removefrompickup, requirement_pickup } from "../../Api";
import CustomLoader from "../../Components/Loader";
import { useScreenWidth } from "../../useScreenWidth";
import { Breadcrumbs, Button, Typography } from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";

import soundEffect from "../../Assets/sounds/beep.mp3";

import { Input } from "semantic-ui-react";

function RemoveChildParts() {
  const isWideScreen = useScreenWidth();
  const [loading, setLoading] = useState(true);
  const [productSKU, setProductSKU] = useState("");
  const [productName, setProductName] = useState("");
  const [partnumber, setPartNumber] = useState("");
  const [remainingQty, setRemQty] = useState("");
  const [reqQty, setReqQty] = useState("");
  const [pickedProducts, setPickedProducts] = useState([]);
  const { pk } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const ws = useRef(null);
  const [quantities, setQuantities] = useState({});
  const maxQty = reqQty - remainingQty || 0;

  const handleQuantityChange = (index, value) => {
    const parsedValue = parseInt(value, 10);

    if (isNaN(parsedValue) || parsedValue < 0) {
      toast.error("Invalid quantity entered.");
      setQuantities((prevState) => ({
        ...prevState,
        [index]: "",
      }));
      return;
    }

    // Ensure the quantity does not exceed the product's available quantity
    if (parsedValue > pickedProducts[index].quantity) {
      toast.error(`Quantity cannot exceed ${pickedProducts[index].quantity}.`);
      setQuantities((prevState) => ({
        ...prevState,
        [index]: "",
      }));
      return;
    }

    // // Calculate the total quantity after the change
    // const newQuantities = { ...quantities, [index]: parsedValue };
    // const newTotalQty = Object.values(newQuantities).reduce(
    //   (sum, qty) => sum + (qty || 0),
    //   0
    // );

    // if (newTotalQty > maxQty) {
    //   toast.error(`Total quantity cannot exceed ${maxQty}.`);
    //   setQuantities((prevState) => ({
    //     ...prevState,
    //     [index]: "",
    //   }));
    //   return;
    // }

    setQuantities((prevState) => ({
      ...prevState,
      [index]: parsedValue,
    }));
  };

  const submitRemove = () => {
    // Construct the details array
    let detailsArray = pickedProducts.map((product, index) => ({
      ware_house: product.warehouse_pk, // Assuming 'warehouse_pk' is the correct key for the warehouse ID
      quantity: quantities[index] || 0,
    }));

    // Check if all quantities are valid
    if (detailsArray.some((item) => item.quantity <= 0)) {
      toast.error("Please enter valid quantities for all products.");
      return;
    }

    // Construct the submitPayload
    let submitPayload = {
      item_pk: parseInt(pk),
      details: detailsArray,
      t_qty: detailsArray.reduce((total, item) => total + item.quantity, 0),
    };

    // Make the POST request
    axios
      .post(removefrompickup, submitPayload, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        console.log(
          response.data.data.map(
            (data) =>
              data.warehouse_name + ">>" + data.row_name + ">>" + data.rack_name
          )
        );
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    // Establish WebSocket connection with API-Key as a query parameter
    ws.current = new WebSocket(
      `${requirement_pickup}?requirement_item_pk=${pk}&API-Key=${apiKey}`
    );

    ws.current.onopen = () => {
      console.log("WebSocket connected");
      setLoading(false);
    };

    ws.current.onmessage = (event) => {
      const response = JSON.parse(event.data);

      setProductSKU(response.obj.product_sku);
      setProductName(response.obj.product_name);
      setPartNumber(response.obj.part_number);
      setRemQty(response.obj.picked_up_quantity);
      setReqQty(response.obj.required_quantity);

      setPickedProducts(
        Object.values(response.obj.picked_up_detail).map((data) => data)
      );
    };

    ws.current.onerror = (error) => {
      console.error(error, "WebSocket error");
    };

    ws.current.onclose = () => {
      console.log("WebSocket disconnected");
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [pk, apiKey]);

  useEffect(() => {
    if (!loading) {
      fetchData();
    }
  }, [searchQuery]);

  const fetchData = (query = searchQuery) => {
    const bodyData = {
      requirement_pk: parseInt(pk),
      search: query,
    };

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(bodyData));
    }
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />
      <audio id="scanSound" src={soundEffect} />
      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Requirement</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Remove Items
              </Typography>
            </Breadcrumbs>
          </div>

          <Button
            style={{ float: "right", marginTop: "-27px" }}
            variant="contained"
            size="small"
            disabled={!quantities}
            onClick={submitRemove}
          >
            Confirm Remove
          </Button>

          <div className="card p-3 my-2">
            <h5>Product Details:</h5>
            <div className="row">
              <div className="col-lg-2">#{productSKU}</div>
              <div
                className="col-lg-5"
                style={{ borderLeft: "1px solid #c7c7c7" }}
              >
                {productName}
              </div>
              <div
                className="col-lg-3"
                style={{ borderLeft: "1px solid #c7c7c7" }}
              >
                Part No.: {partnumber}
              </div>
              <div
                className="col-lg-1"
                style={{ borderLeft: "1px solid #c7c7c7" }}
              >
                Reqd Qty: {reqQty || 0}
              </div>

              <div
                className="col-lg-1"
                style={{ borderLeft: "1px solid #c7c7c7" }}
              >
                Remaining Qty:{" "}
                <b style={{ color: "red" }}>{reqQty - remainingQty || 0}</b>
              </div>
            </div>
          </div>

          <div className="card p-3 my-2">
            <div className="row">
              <>
                <div className="col-md-12">
                  {isWideScreen ? (
                    <>
                      <div className="responsive-table1">
                        <table className="ui table celled" id="c_table">
                          <thead className="table-head">
                            <tr>
                              <th>Warehouse</th>
                              <th>Row</th>
                              <th>Rack</th>
                              <th>Qty</th>
                              <th>Series</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pickedProducts.length > 0 ? (
                              <>
                                {pickedProducts.map((product, index) => (
                                  <tr key={index}>
                                    <td>{product.warehouse_name}</td>
                                    <td>{product.row_name}</td>
                                    <td>{product.rack_name}</td>
                                    <td>{product.quantity} QTY</td>
                                    <td>
                                      <Input
                                        placeholder="Quantity"
                                        value={quantities[index] || ""}
                                        onChange={(e) =>
                                          handleQuantityChange(
                                            index,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <>
                                <tr>
                                  <td
                                    colSpan={4}
                                    style={{
                                      textAlign: "center",
                                      color: "red",
                                    }}
                                  >
                                    No Scanned Products
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="card p-2 responsive-mobile-table">
                        {pickedProducts.length > 0 ? (
                          <>
                            {pickedProducts &&
                              pickedProducts.map((product, index) => (
                                <>
                                  <div className="product-card" key={index}>
                                    <div className="py-1 px-2">
                                      <h5 className="product-card-sku">
                                        {product.warehouse_name}
                                      </h5>
                                      <h5 className="product-card-name">
                                        {product.row_name}
                                      </h5>
                                      <h5 className="product-card-qty">
                                        {product.rack_name}
                                      </h5>
                                      <h5 className="product-card-qty">
                                        {product.quantity} Qty
                                      </h5>
                                      <Input
                                        placeholder="Quantity"
                                        value={quantities[index] || ""}
                                        onChange={(e) =>
                                          handleQuantityChange(
                                            index,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              ))}
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                textAlign: "center",
                                color: "red",
                                fontWeight: "bold",
                              }}
                            >
                              No Scanned Products
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RemoveChildParts;
