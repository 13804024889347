import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { FaCaretDown } from "react-icons/fa";
import { apiKey, fetch_warehouse } from "../../Api";
import { Checkbox, Input } from "semantic-ui-react";
import { Button } from "@mui/material";

function FilterSelectionModalByWarehouse({ title, selectedWarehouses, onSelect, }) {
    const [warehouses, setWarehouses] = useState([]); // Initialize as an array
    const [searchTerm, setSearchTerm] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [tempSelectedWarehouses, setTempSelectedWarehouses] = useState(
        selectedWarehouses || []
    );
    const buttonRef = useRef(null);
    const modalRef = useRef(null);

    useEffect(() => {
        fetchWarehouse();
    }, []);

    useEffect(() => {
        setTempSelectedWarehouses(selectedWarehouses || []);
    }, [selectedWarehouses]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target) &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const fetchWarehouse = () => {
        try {
            axios
                .get(fetch_warehouse, {
                    headers: {
                        "API-Key": apiKey,
                    },
                })
                .then((response) => {
                    setWarehouses(response.data.warehouses);
                })
                .catch((error) => {
                    console.log(error.response?.data?.message || "Internal Server Error");
                });
        } catch {
            console.log("Error while fetching warehouse");
        }
    };

    const handleOptionChange = (warehouseId) => {
        setTempSelectedWarehouses((prevSelected) => {
            if (prevSelected.includes(warehouseId)) {
                return prevSelected.filter((id) => id !== warehouseId);
            } else {
                return [...prevSelected, warehouseId];
            }
        });
    };

    const handleApply = () => {
        onSelect(tempSelectedWarehouses);
        setIsOpen(false);
    };


    const handleClear = () => {
        setTempSelectedWarehouses([]);
        onSelect([]);
        setIsOpen(false);
    };

    const handleToggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const filteredWarehouses = Array.isArray(warehouses)
        ? warehouses.filter(
            (warehouse) =>
                warehouse.ware_house &&
                warehouse.ware_house
                    .toString()
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
        )
        : [];

    const getModalPosition = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            return {
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
            };
        }
        return {};
    };

    const modalContent = (
        <div className="filter-modal" style={getModalPosition()} ref={modalRef}>
            <Input
                type="text"
                placeholder={`Search ${title}...`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="filter-options">
                {filteredWarehouses.map((warehouse) => (
                    <div key={warehouse.id}>
                        <Checkbox
                            name="warehouse"
                            checked={tempSelectedWarehouses.includes(warehouse.id)}
                            onChange={() => handleOptionChange(warehouse.id)}
                            label={warehouse.ware_house}
                        />{" "}
                    </div>
                ))}
            </div>
            <div className="d-flex justify-content-end mt-2">
                <Button variant="outlined" size="small" color="error" onClick={handleClear} >
                    Clear
                </Button>
                <Button variant="outlined" size="small" className="apply" onClick={handleApply} style={{ marginLeft: "5px" }} >
                    Apply
                </Button>
            </div>
        </div>
    );

    return (
        <div ref={buttonRef}>
            <div
                onClick={handleToggleOpen}
                style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent:"space-between" }}
            >
                {title} <FaCaretDown />
            </div>
            {isOpen && ReactDOM.createPortal(modalContent, document.body)}
        </div>
    );
}

export default FilterSelectionModalByWarehouse;
