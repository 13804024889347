import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { apiKey, requirement_details, removefrompickup } from "../../Api";
import CustomLoader from "../../Components/Loader";
import { useScreenWidth } from "../../useScreenWidth";
import { Breadcrumbs, Button, Typography } from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import Quagga from "quagga";
import Webcam from "react-webcam";
import RemovePickupModal from "../../Messages/RemovePickupModal";
import { Label } from "semantic-ui-react";

function DispatchRequirementDetails() {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [showWarehouse, setShowWarehouse] = useState(false);
  const webcamRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const isWideScreen = useScreenWidth();
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const ws = useRef(null);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const hiddenInputRef = useRef(null);
  const [scanData, setScanData] = useState("");
  useEffect(() => {
    const handleFocus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }

      // Call your input change handler with scanData
      handleInputChange(scanData);

      // Immediately blur the input to hide the keyboard
      if (inputRef.current) {
        inputRef.current.blur();
      }
    };

    document.addEventListener("click", handleFocus);

    return () => {
      document.removeEventListener("click", handleFocus);
    };
  }, []);

  const playSound = () => {
    const sound = document.getElementById("scanSound");
    if (sound) {
      sound.play();
    }
  };

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
  };

  const handleDetected = useCallback((result) => {
    const scannedCode = result.codeResult.code;
    setScanData(scannedCode);
  }, []);

  useEffect(() => {
    handleInputChange(scanData);
  }, [scanData]);

  const isValidProductSku = (value) => {
    const regex = /^\d{6}_\d+$/; // First 5 characters are digits, followed by an underscore, then more digits
    return regex.test(value);
  };

  const [item_pk, setItemPk] = useState("");
  const handleRemove = (pk) => {
    setIsCameraOpen(true);
    setItemPk(pk);
  };

  const [message, setMessage] = useState("");

  const handleInputChange = (scanData) => {
    const newValue = scanData;
    const checkValue = newValue[0];

    if (newValue.length <= 5) {
      setScanData("");
      return;
    }

    const checksku = newValue.split("_")[1];

    if (newValue.length > 5) {
      if (checkValue === "W") {
        playSound();
        toast.error("Oops! You have scanned a warehouse.");
      } else if (isValidProductSku(newValue)) {
        // setScannedSKU("");

        let submitPayload = {
          item_pk: parseInt(item_pk),
          series: parseInt(checksku),
        };

        axios
          .post(removefrompickup, submitPayload, {
            headers: {
              "Content-Type": "application/json",
              "API-Key": apiKey,
            },
          })
          .then((response) => {
            toast.success(response.data.message);
            console.log(
              response.data.data.map(
                (data) =>
                  data.warehouse_name +
                  ">>" +
                  data.row_name +
                  ">>" +
                  data.rack_name
              )
            );
            setIsCameraOpen(false);
            setShowWarehouse(true);
            setMessage(
              `Keep the item in ${response.data.data.map(
                (data) =>
                  "Warehouse: " +
                  data.warehouse_name +
                  (data.row_name !== "-"
                    ? " >> Row: " + data.row_name + " >> Rack:" + data.rack_name
                    : "")
              )}`
            );
          })
          .catch((error) => {
            toast.error(error.response.data.message);
            setIsCameraOpen(false);
          });
      } else {
        playSound();
        toast.error("Oops! You have scanned the wrong product.");
      }
    }

    setScanData("");
  };

  useEffect(() => {
    // Establish WebSocket connection with API-Key as a query parameter
    ws.current = new WebSocket(
      `${requirement_details}?requirement_pk=${id}&API-Key=${apiKey}`
    );

    ws.current.onopen = () => {
      console.log("WebSocket connection established");
      setLoading(false);
    };

    ws.current.onmessage = (event) => {
      const response = JSON.parse(event.data);
      // console.log(response.items);
      setData(response.items);
      // Assuming response.data.purchase_detail.items_detail is the data you need
      // setData(response.data.purchase_detail.items_detail);
    };

    ws.current.onerror = (error) => {
      console.error(error, "WebSocket error");
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [id, apiKey]);

  useEffect(() => {
    if (!loading) {
      fetchData();
    }
  }, [searchQuery]);

  const fetchData = (query = searchQuery) => {
    const bodyData = {
      requirement_pk: parseInt(id),
      search: query,
    };

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(bodyData));
    }
  };

  const handlePickUp = (pk) => {
    navigate(`/requirement-pick-up/${pk}`);
  };

  const handleRemoveChild = (pk) => {
    navigate(`/remove-child-parts-requirement/${pk}`);
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />

      <RemovePickupModal
        message={message}
        setShowWarehouse={setShowWarehouse}
        showWarehouse={showWarehouse}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate(-1)} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Requirement</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Requirement Details
              </Typography>
            </Breadcrumbs>
          </div>

          {isCameraOpen ? (
            <div className="scannerDiv">
              <input
                autoFocus
                value={scanData}
                ref={inputRef}
                id="desktop_scan"
                style={{
                  border: "1px solid #d1d1d1",
                  borderRadius: "4px",
                  maxWidth: "150px",
                  fontSize: "15px",
                  color: "grey",
                  padding: "5px",
                  display: "none",
                }}
                onChange={(e) => setScanData(e.target.value)}
              />

              <input
                id="desktop_scan"
                ref={hiddenInputRef}
                onChange={(e) => setScanData(e.target.value)}
                value={scanData}
                style={{
                  border: "1px solid #d1d1d1",
                  borderRadius: "4px",
                  maxWidth: "150px",
                  fontSize: "15px",
                  color: "grey",

                  padding: "5px",
                }}
              />
              <div className="mt-1"></div>
              <Webcam
                audio={false}
                ref={webcamRef}
                width={1920}
                height={220}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  facingMode: "environment",

                  border: "none",
                }}
                style={{
                  marginBottom: "12px",
                  border: "3px dashed green",
                  padding: "0",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
              <Button
                variant="outlined"
                color="error"
                onClick={handleCloseCamera}
              >
                Close
              </Button>
            </div>
          ) : null}

          <div className="row my-1">
            {isWideScreen ? (
              <>
                <div className="responsive-table1">
                  <table className="ui table celled" id="c_table">
                    <thead className="table-head">
                      <tr>
                        <th>#</th>
                        <th>Product SKU</th>
                        <th>Product Name</th>
                        <th>Product Type</th>
                        <th>Reqd. Qty</th>
                        <th>Picked Qty</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? (
                        <>
                          {data &&
                            data.map((req, index) => (
                              <>
                                <tr
                                  key={index}
                                  style={{
                                    backgroundColor:
                                      req.quantity < req.picked_quantity
                                        ? "rgb(253 199 199)"
                                        : req.quantity == req.picked_quantity
                                        ? "#d1e7dd"
                                        : req.quantity > req.picked_quantity &&
                                          req.picked_quantity != 0
                                        ? "#fff3cd"
                                        : req.picked_quantity == 0
                                        ? "transparent"
                                        : "transparent",
                                  }}
                                >
                                  <td style={{ width: "40px" }}>{index + 1}</td>
                                  <td>{req.product_sku}</td>
                                  <td>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handlePickUp(req.id)}
                                    >
                                      {req.product_name}
                                    </span>
                                  </td>
                                  <td>{req.product_type}</td>
                                  <td>{req.quantity}</td>
                                  <td>{req.picked_quantity}</td>
                                  <td style={{ backgroundColor: "grey" }}>
                                    {req.quantity < req.picked_quantity ? (
                                      <>
                                        {req.product_type == "Child Parts" ||
                                        req.product_type == "CH" ? (
                                          <>
                                            <Button
                                              disabled={
                                                req.quantity ===
                                                req.picked_quantity
                                              }
                                              variant="outlined"
                                              size="small"
                                              onClick={() =>
                                                handleRemoveChild(req.id)
                                              }
                                            >
                                              Remove
                                            </Button>
                                          </>
                                        ) : (
                                          <>
                                            <Button
                                              disabled={
                                                req.quantity ===
                                                req.picked_quantity
                                              }
                                              variant="outlined"
                                              size="small"
                                              onClick={() =>
                                                handleRemove(req.id)
                                              }
                                            >
                                              Remove
                                            </Button>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <Button
                                          disabled={
                                            req.quantity === req.picked_quantity
                                          }
                                          variant="outlined"
                                          size="small"
                                          onClick={() => handlePickUp(req.id)}
                                        >
                                          Pick
                                        </Button>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              </>
                            ))}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colSpan={10}>
                              {!loading ? (
                                <div className="notfound_img_div">
                                  <div className="notfound_img"></div>
                                </div>
                              ) : (
                                <div className="notfound_img_div">
                                  <div className="loadingfound_img"></div>
                                </div>
                              )}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div className="card p-2 responsive-mobile-table">
                  {data.length > 0 ? (
                    <>
                      {data &&
                        data.map((req, index) => (
                          <>
                            <div
                              key={index}
                              className="product-card"
                              style={{
                                minHeight: "8vh",
                                backgroundColor:
                                  req.quantity < req.picked_quantity
                                    ? "rgb(253 199 199)"
                                    : req.quantity == req.picked_quantity
                                    ? "#d1e7dd"
                                    : req.quantity > req.picked_quantity &&
                                      req.picked_quantity != 0
                                    ? "#fff3cd"
                                    : req.picked_quantity == 0
                                    ? "transparent"
                                    : "transparent",
                              }}
                            >
                              <div className="py-1 px-2">
                                <h5 className="product-card-sku">
                                  #{req.product_sku}
                                </h5>
                                <h5
                                  className="product-card-name"
                                  onClick={() => handlePickUp(req.id)}
                                >
                                  {req.product_name}
                                </h5>
                                <h5 className="product-card-mrp">
                                  {req.product_type}
                                </h5>

                                {req.quantity < req.picked_quantity ? (
                                  <>
                                    {req.product_type == "Child Parts" ||
                                    req.product_type == "CH" ? (
                                      <>
                                        <Button
                                          style={{
                                            position: "absolute",
                                            bottom: "5px",
                                            right: "5px",
                                          }}
                                          disabled={
                                            req.quantity === req.picked_quantity
                                          }
                                          variant="outlined"
                                          size="small"
                                          onClick={() =>
                                            handleRemoveChild(req.id)
                                          }
                                        >
                                          Remove
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        <Button
                                          style={{
                                            position: "absolute",
                                            bottom: "5px",
                                            right: "5px",
                                          }}
                                          disabled={
                                            req.quantity === req.picked_quantity
                                          }
                                          variant="outlined"
                                          size="small"
                                          onClick={() => handleRemove(req.id)}
                                        >
                                          Remove
                                        </Button>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      style={{
                                        position: "absolute",
                                        bottom: "5px",
                                        right: "5px",
                                      }}
                                      disabled={
                                        req.quantity === req.picked_quantity
                                      }
                                      variant="outlined"
                                      size="small"
                                      onClick={() => handlePickUp(req.id)}
                                    >
                                      Pick
                                    </Button>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                    </>
                  ) : (
                    <>
                      {!loading ? (
                        <div className="notfound_img_div">
                          <div className="notfound_img"></div>
                        </div>
                      ) : (
                        <div className="notfound_img_div">
                          <div className="loadingfound_img"></div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default DispatchRequirementDetails;
