import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiKey, fetch_purchase_detail, fetch_vendor } from "../../Api";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { TbExternalLink } from "react-icons/tb";
import { useScreenWidth } from "../../useScreenWidth";
import { Label } from "semantic-ui-react";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: {
  //     xs: "90%", // Mobile size
  //     sm: "80%", // Small size (tablet)
  //     md: "750px" // Medium and larger (desktop)
  // },
  width: "90%",
  maxWidth: "950px",
  bgcolor: "#fff",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

function ViewPurchaseModal({ open, onClose, purchaseId }) {
  const isWideScreen = useScreenWidth();
  const [loading, setLoading] = useState(true);
  const [purchase, setPurchase] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    if (open && purchaseId) {
      fetchVendors();
      fetchPurchases(purchaseId);
    }
  }, [open, purchaseId]);

  const fetchVendors = () => {
    axios
      .get(fetch_vendor, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setVendors(response.data.vendors);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchPurchases = (id) => {
    const bodyData = {
      purchase_pk: parseInt(id),
    };
    axios
      .post(fetch_purchase_detail, bodyData, {
        headers: {
          "API-Key": apiKey,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setPurchase(response.data.purchase_detail);
        setSelectedProducts(response.data.purchase_detail.items_detail);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div style={modalStyle} className="bg-white py-3 px-3">
        {loading ? (
          <Typography variant="h6">Loading...</Typography>
        ) : (
          <>
            <Typography variant="h5" component="h2">
              Purchase Details <small>(#{purchaseId})</small>
              <a
                href={`/#/view-purchase/${purchaseId}`}
                target="_blank"
                className="navigate-other"
              >
                <TbExternalLink />
              </a>
            </Typography>
            <div className="row my-2">
              <div className="col-md-4">
                <Typography>
                  <strong>Vendor:</strong>{" "}
                  {vendors.find((v) => v.id === purchase.vendor_ref?.id)?.name}
                </Typography>
              </div>
              <div className="col-md-4">
                <Typography>
                  <strong>Date:</strong> {purchase.purchase_date}
                </Typography>
              </div>
              <div className="col-md-4">
                <Typography>
                  <strong>Reference No:</strong>{" "}
                  {purchase.reference_number || "N/A"}
                </Typography>
              </div>
            </div>

            {isWideScreen ? (
              <>
                <div
                  className="responsive-table1 "
                  style={{
                    maxHeight: "300px",
                    height: "max-content",
                    width: "100%",
                  }}
                >
                  <table className="ui table celled" id="c_table">
                    <thead className="table-head">
                      <tr>
                        <th>SKU</th>
                        <th>Product</th>
                        <th>Part No</th>
                        <th>OEM No.</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedProducts &&
                        selectedProducts.map((data, index) => (
                          <tr key={index}>
                            <td>{data.product_sku}</td>
                            <td>{data.product_name}</td>
                            <td>{data.part_number}</td>
                            <td>{data.oem_number}</td>
                            <td>{data.quantity}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div
                  className="card p-2 responsive-mobile-table"
                  style={{ maxHeight: "250px" }}
                >
                  {selectedProducts.length > 0 ? (
                    <>
                      {selectedProducts &&
                        selectedProducts.map((data) => (
                          <>
                            <div className="product-card">
                              <div className="py-1 px-2">
                                <h5 className="product-card-sku">
                                  #{data.product_sku}
                                </h5>
                                <h5 className="product-card-name">
                                  {data.product_name}
                                </h5>
                                <small>
                                  {data.part_number} || {data.oem_number}
                                </small>
                                <Label
                                  color={"green"}
                                  style={{
                                    position: "absolute",
                                    bottom: "5%",
                                    right: "9px",
                                    fontSize: "11px",
                                    cursor: "pointer",
                                  }}
                                  className="product-card-mrp"
                                >
                                  QTY {data.quantity}
                                </Label>
                              </div>
                            </div>
                          </>
                        ))}
                    </>
                  ) : (
                    <>
                      {!loading ? (
                        <div className="notfound_img_div">
                          <div className="notfound_img"></div>
                        </div>
                      ) : (
                        <div className="notfound_img_div">
                          <div className="loadingfound_img"></div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}

            <Button
              onClick={onClose}
              variant="outlined"
              color="primary"
              style={{ marginTop: "20px", float: "right" }}
            >
              Close
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
}

export default ViewPurchaseModal;
