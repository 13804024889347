import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { apiKey, fetch_sale_detail } from "../../Api";
import { useNavigate, useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IoMdArrowRoundBack } from "react-icons/io";
import toast, { Toaster } from "react-hot-toast";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";
import { TbExternalLink } from "react-icons/tb";
import { Button } from "@mui/material";
import { useScreenWidth } from "../../useScreenWidth";
import { Label } from "semantic-ui-react";

function ViewSaleDetail() {
  const isWideScreen = useScreenWidth();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { id } = useParams();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [customer, setCustomer] = useState("");
  const [address, setAddress] = useState("");
  const [note, setNote] = useState("");
  const [sale_date, setSaleDate] = useState("");
  const [discountedprice, setDiscountedPrice] = useState(0);
  const [totalamount, setTotalAmount] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [othercharge, setOtherCharge] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSales();
  }, [id]);

  const fetchSales = () => {
    axios
      .get(fetch_sale_detail, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          sale_pk: parseInt(id),
        },
      })
      .then((response) => {
        const data = response.data.data;
        console.log("response.data.data :", response.data.data);

        // Set customer and other details from the API response
        setCustomer(data.billing_customer.customer_name);
        setNote(data.note);
        setSaleDate(data.sale_date);
        setDiscountedPrice(data.discount_amount);
        setTotalAmount(data.total_amount);
        setOtherCharge(data.other_charge);
        setShipping(data.courier_charge);
        setAddress(
          data.billing_customer.primary_shipping?.address ||
            "No Address Provided"
        );

        // Set selected products based on the API response
        const items = data.items.map((item) => ({
          product_sku: item.product_sku,
          product_name: item.product_name,
          mrp: item.mrp,
          price: item.price,
          quantity: item.quantity,
          discount_amount: item.discount_amount,
        }));

        setSelectedProducts(items);
      })
      .catch((error) => {
        console.log(error);
        // setOpenError(true);
        toast.error("Failed to fetch sale details.");
      });
  };

  return (
    <>
      <Toaster position="top-right" />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <IoMdArrowRoundBack id="backbtn" onClick={() => navigate(-1)} />
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="inherit">Sales</Typography>
                <Typography color="inherit">Sale Details</Typography>
                <Typography sx={{ color: "text.primary" }}>#{id}</Typography>
              </Breadcrumbs>
            </div>
            {isWideScreen ? (
              <Button
                style={{ marginLeft: "5px" }}
                variant="outlined"
                color="info"
                size="small"
                onClick={() => navigate(`/dispatch-details/${id}`)}
              >
                Dispatch History
              </Button>
            ) : null}
          </div>

          <div className="row my-2">
            <div className="col-lg-9">
              <div className="card p-4">
                <div className="row">
                  <div className="col-lg-3">
                    Customer: <span>{customer}</span>
                  </div>
                  <div className="col-lg-3">Date: {sale_date}</div>
                  <div className="col-lg-4">Bill to: {address}</div>
                  <div className="col-lg-2">Note: {note}</div>

                  {isWideScreen ? null : (
                    <Button
                      style={{ marginTop: "5px" }}
                      variant="outlined"
                      color="info"
                      size="small"
                      onClick={() => navigate(`/dispatch-details/${id}`)}
                    >
                      Dispatch History
                    </Button>
                  )}
                </div>
              </div>

              <div className="card my-2" style={{ padding: "10px" }}>
                {isWideScreen ? (
                  <>
                    <div className="responsive-table1">
                      <table className="ui table celled" id="c_table">
                        <thead className="table-head">
                          <tr>
                            <th>SKU</th>
                            <th align="left">Product</th>
                            <th align="left">MRP</th>
                            <th align="left">Discount</th>
                            <th align="right">Discount Amt</th>
                            <th align="center">Quantity</th>
                            <th align="right">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedProducts.length > 0 ? (
                            selectedProducts.map((product, index) => (
                              <tr key={index}>
                                <td component="th" scope="row">
                                  {product.product_sku}
                                </td>
                                <td align="left">{product.product_name}</td>
                                <td align="left">₹{product.mrp}.00</td>
                                <td align="left">
                                  {(
                                    (product.discount_amount / product.mrp) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </td>
                                <td align="right">
                                  ₹{product.discount_amount}.00
                                </td>
                                <td align="center">{product.quantity}</td>
                                <td align="right">
                                  ₹{product.quantity * product.price}.00
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="7"
                                style={{ textAlign: "center", color: "red" }}
                              >
                                No Products Selected
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="card p-2 responsive-mobile-table">
                      {selectedProducts.length > 0 ? (
                        <>
                          {selectedProducts &&
                            selectedProducts.map((data) => (
                              <>
                                <div className="product-card">
                                  <div className="py-1 px-2">
                                    <h5 className="product-card-sku">
                                      #{data.product_sku}
                                    </h5>
                                    <h5 className="product-card-name">
                                      {data.product_name}
                                    </h5>
                                    <small>
                                      ₹{data.quantity * data.price}.00
                                    </small>

                                    <Label
                                      color={"green"}
                                      style={{
                                        position: "absolute",
                                        marginTop: "-17px",
                                        right: "9px",
                                        fontSize: "11px",
                                      }}
                                      className="product-card-mrp"
                                    >
                                      {data.quantity} QTY
                                    </Label>
                                  </div>
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <>
                          {
                            <div className="notfound_img_div">
                              <div className="loadingfound_img"></div>
                            </div>
                          }
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card p-3">
                <div className="row" style={{ marginTop: "2px" }}>
                  <div className="col-6 my-2">Sub-Total</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    ₹{totalamount.toFixed(2)}
                  </div>
                  <div className="col-6 my-2">Total Discount (-)</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    ₹{discountedprice.toFixed(2)}
                  </div>
                  <div className="col-6 my-2">Shipping Charge</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    ₹{shipping.toFixed(2)}
                  </div>
                  <div className="col-6 my-2">Other Charges</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    ₹{othercharge.toFixed(2)}
                  </div>
                  <div className="col-6 my-2">
                    <strong>Total Amount</strong>
                  </div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <strong>
                      ₹
                      {(
                        totalamount -
                        discountedprice +
                        shipping +
                        othercharge
                      ).toFixed(2)}
                    </strong>
                  </div>
                  {/* <div className="col-6 my-2" style={{ textAlign: "right" }}><strong>₹{(totalamount - discountedprice + shipping + othercharge).toFixed(2)}.00</strong></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewSaleDetail;
