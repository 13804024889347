import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import { apiKey, pending_dispatch } from "../../Api";
import { Link, useNavigate } from "react-router-dom";
import "./Dashboard.css";
import AllDispatchModal from "../Dispatch/AllDispatchModal.js";
import { useScreenWidth } from "../../useScreenWidth.js";
import {
  AiOutlineProduct,
  AiOutlineWarning,
  AiOutlineEdit,
  AiOutlineSwap,
  AiOutlineArrowRight,
  AiOutlineFileAdd,
  AiOutlineFileText,
  AiOutlineShoppingCart,
  AiOutlinePlusSquare,
  AiOutlineTeam,
  AiOutlineUser,
  AiOutlineCheckCircle,
  AiOutlineHistory,
} from "react-icons/ai";
import {
  MdDoubleArrow,
  MdInventory,
  MdOutlinePendingActions,
} from "react-icons/md";

import SaleTypeModal from "../Sales/SelectSaleTypeModal.js";

function Dashboard() {
  const [dispatchData, setDispatchData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDispatchData();
  }, []);

  const fetchDispatchData = () => {
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(pending_dispatch, {}, { headers })
      .then((response) => {
        setDispatchData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // Open View all Dispatch modal
  const [openViewAllDispatchModal, setOpenViewAllDispatchModal] =
    useState(false);

  const handleOpenViewAllDispatchModal = () =>
    setOpenViewAllDispatchModal(true);
  const handleCloseViewAllDispatchModal = () =>
    setOpenViewAllDispatchModal(false);

  //   Resposive Design
  const isWideScreen = useScreenWidth();

  const [openSaleType, setOpenSaleType] = useState(false);
  const handleSaleType = () => {
    setOpenSaleType(true);
  };

  const sections = [
    {
      title: "Products",
      links: [
        { to: "/all_products", icon: <AiOutlineProduct />, label: "Products" },
        // { to: "/add_product", icon: <AiOutlinePlusCircle />, label: "Add" },
        {
          to: "/unallocated-products",
          icon: <AiOutlineWarning />,
          label: "Unallocated",
        },
      ],
    },
    {
      title: "Inventory",
      links: [
        { to: "/warehouses", icon: <MdInventory />, label: "Warehouses" },
        {
          to: "/stock_adjustments",
          icon: <AiOutlineEdit />,
          label: "Adjustments",
        },
        { to: "/conversions", icon: <AiOutlineSwap />, label: "Conversion" },
        {
          to: "/stock_transfer",
          icon: <AiOutlineArrowRight />,
          label: "Transfer",
        },
      ],
    },
    {
      title: "Sales & Purchases",
      links: [
        { to: "/all_sales", icon: <AiOutlineShoppingCart />, label: "Sales" },
        {
          onClick: handleSaleType,
          icon: <AiOutlinePlusSquare />,
          label: "Create Sale",
        },
        {
          to: "/all_purchases",
          icon: <AiOutlineFileAdd />,
          label: " Purchases",
        },
        {
          to: "/all-draft-purchase",
          icon: <AiOutlineFileText />,
          label: "Draft",
        },
      ],
    },
    {
      title: "Dispatch",
      links: [
        {
          to: "/dispatch-history",
          icon: <AiOutlineHistory />,
          label: "Dispatch History",
        },
        {
          to: "#",
          icon: <MdOutlinePendingActions />,
          label: "Pending Dispatch",
          onClick: handleOpenViewAllDispatchModal,
        },
      ],
    },
    {
      title: "Customers & Vendors",
      links: [
        { to: "/all-vendors", icon: <AiOutlineTeam />, label: "Vendors" },
        { to: "/all-customers", icon: <AiOutlineUser />, label: "Customers" },
      ],
    },
    {
      title: "Requirements",
      links: [
        {
          to: "/requirements-list",
          icon: <AiOutlineCheckCircle />,
          label: "Requirement List",
        },
      ],
    },
  ];

  return (
    <>
      <SaleTypeModal
        openSaleType={openSaleType}
        setOpenSaleType={setOpenSaleType}
      />
      <AllDispatchModal
        openViewAllDispatchModal={openViewAllDispatchModal}
        handleCloseViewAllDispatchModal={handleCloseViewAllDispatchModal}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          {isWideScreen ? (
            <>
              <div className="row my-4">
                <div className="col-md-3 mb-2">
                  <div className="card rounded text-center">
                    <div className="card-body ">
                      <h5 className="card-title">Total Products</h5>
                      <h4 className="card-text">--</h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-2">
                  <div className="card rounded text-center">
                    <div className="card-body">
                      <h5 className="card-title">Total Sales</h5>
                      <h4 className="card-text">--</h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-2">
                  <div className="card rounded text-center">
                    <div className="card-body">
                      <h5 className="card-title">Total Purchases</h5>
                      <h4 className="card-text">--</h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-2">
                  <div className="card rounded text-center">
                    <div className="card-body">
                      <h5 className="card-title">Total Clients</h5>
                      <h4 className="card-text">--</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8">
                  <div className="card">
                    <iframe
                      width="100%"
                      height="593"
                      src="https://www.youtube.com/embed/SYKmgHiST5w"
                      title="New bike dekh liya | Met him after 7years | Bike mein penniers lagwa di"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Pending Dispatches</h5>
                      {loading ? (
                        <p>Loading...</p>
                      ) : error ? (
                        <p>Error fetching data: {error.message}</p>
                      ) : (
                        <>
                          {dispatchData && dispatchData.length > 0 ? (
                            <div
                              className="col-md-12 "
                              style={{ maxHeight: "400px", overflow: "auto" }}
                            >
                              {dispatchData
                                .slice(0, 5)
                                .map((dispatch, index) => (
                                  <div
                                    key={dispatch.id}
                                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between mb-1 border border-1 rounded p-1 hover-div"
                                  >
                                    <div>
                                      <h5
                                        className="mb-0 color-primary"
                                        style={{
                                          color: "#2e3955",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {
                                          dispatch.billing_customer
                                            .customer_name
                                        }
                                      </h5>
                                      <p className="text-muted mb-0">
                                        {dispatch.total_products} /{" "}
                                        {dispatch.sale_date}
                                      </p>
                                    </div>
                                    <div>
                                      <Button
                                        className=" ms-2"
                                        variant="outlined"
                                        color="error"
                                        onClick={() =>
                                          navigate(`/view-sale/${dispatch.id}`)
                                        }
                                      >
                                        View
                                      </Button>
                                      <Button
                                        className=" ms-2"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          navigate(
                                            `/dispatch-sale-scan/${dispatch.id}`
                                          )
                                        }
                                      >
                                        dispatch
                                      </Button>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          ) : (
                            <p>No pending dispatches found.</p>
                          )}
                        </>
                      )}
                      <div className="text-center mt-2">
                        <button
                          className="btn-1 float-right"
                          onClick={handleOpenViewAllDispatchModal}
                        >
                          View All Dispatches
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Mobile Dashboard */}

              <div className="dashboard">
                <div className="bg-white rounded p-3">
                  {sections.map((section, index) => (
                    <div className="single-row" key={index}>
                      <h4>
                        <MdDoubleArrow style={{ marginTop: "-0.2rem" }} />{" "}
                        {section.title}{" "}
                      </h4>
                      <div className="grid-4">
                        {section.links.map((link, linkIndex) => (
                          <div className="single-link" key={linkIndex}>
                            {link.onClick ? (
                              <div
                                className="link"
                                onClick={link.onClick}
                                style={{ cursor: "pointer" }}
                              >
                                {link.icon}
                                <h5>{link.label}</h5>
                              </div>
                            ) : (
                              <Link to={link.to}>
                                {link.icon}
                                <h5>{link.label}</h5>
                              </Link>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
